var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"UserUpdateValidation"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"User name*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"User Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter user name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Phone Number*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Phone Number","rules":"required|min:10|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Enter Email"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('br'),_c('br'),_c('b-col',{staticClass:"mb-1 text-center",attrs:{"md":"12"}},[_c('b-button',{staticClass:"form_submit_button",attrs:{"type":"submit","variant":"none"},on:{"click":function($event){return _vm.validationUserUpdateForm()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v("Update")])])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }